"use client";
import Link from "next/link";
import { useEffect, useState } from "react";
import { FiChevronDown, FiChevronRight } from "../../../react-icons/fi";
import classNames from "classnames";

type Props = {
  breadcrumbs: { breadcrumb: string; href: string; }[];
};

export function BreadcrumbsMobile({ breadcrumbs }: Props) {
  const [choicesActive, setChoicesActive] = useState<boolean>(false);

  useEffect(() => {
    setChoicesActive(false);
  }, [breadcrumbs]);

  return (
    <>
      <div
        className={classNames("choices", choicesActive ? "active" : "inactive")}
      >
        {breadcrumbs?.map((breadcrumb, index) => index !== breadcrumbs.length - 1 && (
          <Link
            href={breadcrumb.href}
            key={breadcrumb.href}
            className="item"
          >
            {breadcrumb.breadcrumb} <FiChevronRight size={20} />
          </Link>
        ))}
      </div>

      {breadcrumbs[breadcrumbs.length - 1] && (
        <div
          className={classNames(
            "item selected",
            choicesActive ? "active" : "inactive",
            breadcrumbs.length !== 1 && "clickable"
          )}
          onClick={() => {
            breadcrumbs.length !== 1
              ? setChoicesActive((prev) => !prev)
              : () => { };
          }}
        >
          {breadcrumbs.length !== 1 && <FiChevronDown size={20} />}
          {breadcrumbs[breadcrumbs.length - 1].breadcrumb}
        </div>
      )}
    </>
  );
}
