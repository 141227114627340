"use client";
import React, { useEffect, useState } from "react";

import { HeaderUserLayout } from "ui/components/layout";
import { FaPowerOff, FaUserCog } from "ui/react-icons/fa";
import { useRouter } from "next/navigation";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { BsFillMoonFill, BsFillSunFill } from "ui/react-icons/bs";
import { expertFetcher } from "@/lib/http";
import { setDarkmode } from "@/redux/slices/StyleSlice";

export default function HeaderUser() {
  const [isLoading, setIsLoading] = useState(true);

  const router = useRouter();
  const dispatch = useAppDispatch();
  const darkmode = useAppSelector((state) => state.style.darkmode);
  const user = useAppSelector((state) => state.users.user);
  const picture = useAppSelector((state) => state.users.picture);

  const handleLogout = () => {
    router.push("/auth/deconnexion");
  };

  function updateThemePreference(theme: string) {
    expertFetcher
      .put("/user/update-theme-preference", { theme })
      .then(resp => {
        if (resp?.status === 200) {
          dispatch(setDarkmode(theme));
        }
      });
  }

  useEffect(() => {
    isLoading && user?.firstName && setIsLoading(false);
  }, [user]);

  return (
    <HeaderUserLayout user={user} picture={picture} darkmode={darkmode}>
      <li onClick={() => router.push("/dashboard/mes-informations-personnelles")}>
        <FaUserCog size={14} color="var(--color-gray13)" />
        Mon compte
      </li>

      {darkmode ? (
        <li onClick={() => updateThemePreference("light")}>
          <BsFillSunFill size={12} color="var(--color-gray13)" />
          Mode clair
        </li>
      ) : (
        <li onClick={() => updateThemePreference("dark")}>
          <BsFillMoonFill size={12} color="var(--color-gray13)" />
          Mode sombre
        </li>
      )}

      <li onClick={handleLogout}>
        <FaPowerOff size={12} color="var(--color-gray13)" />
        Déconnexion
      </li>
    </HeaderUserLayout>
  );
}
