"use client";

import React, { useState, type JSX } from "react";
import Link from "next/link";
import { FaUserAlt } from "../../../react-icons/fa";

export function Header() {
  const [showMenuBurger, setShowMenuBurger] = useState(false);
  type NavLinkType = {
    url: string;
    legend: string;
    icon: JSX.Element;
  };

  const NAVLINKS = [
    {
      url: "/auth/connexion",
      legend: "Connexion",
      icon: <FaUserAlt size={17} color={"var(--color-text)"} />,
    },
  ];

  return (
    <header>
      <nav>
        <Link href={"/"}>
          <img src="/logo.png" alt="" id="logo" />
        </Link>
        <ul>
          {NAVLINKS.map((navlink: NavLinkType, key: number) => {
            return (
              <li key={key}>
                <Link href={navlink.url}>
                  <>{navlink.icon}</>
                  {navlink.legend}
                </Link>
              </li>
            );
          })}
        </ul>
        {/* <MenuBurgerButton cb={setShowMenuBurger} value={showMenuBurger}/> */}
      </nav>
    </header>
  );
}
