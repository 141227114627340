import { setIsOpen } from "@/redux/slices/support/TicketSlice";
import { useAppDispatch } from "@/redux/store";
import { useRouter } from "next/navigation";
import React, { type JSX } from "react";
import { Grid } from "ui/components/blocs";

type Props = {
  legend: string;
  url: string;
  icon: JSX.Element;
};

export default function Item({ legend, url, icon }: Props) {
  const router = useRouter();
  const dispatch = useAppDispatch();

  return (
    <button
      className="item clickable"
      onClick={() => {
        dispatch(setIsOpen(false));
        router.push(url);
      }}
    >
      {/* <Grid template="auto 1fr" alignItems="center" mediaQuery={0}> */}
      {icon}
      <span>{legend}</span>
      {/* </Grid> */}
    </button>
  );
}
