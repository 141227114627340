import React from "react";
import { Copyright } from "..";

export function Footer({ CURRENT_VERSION }) {
  return (
    <footer>
      <Copyright CURRENT_VERSION={CURRENT_VERSION} />
    </footer>
  );
}
