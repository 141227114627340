import { setIsOpen } from "@/redux/slices/support/TicketSlice";
import { useAppDispatch } from "@/redux/store";
import React from "react";
import { MdOutlineQuestionMark } from "ui/react-icons/md";

export default function SupportBtn() {
  const dispatch = useAppDispatch();

  return (
    <button aria-label="support" onClick={() => dispatch(setIsOpen(true))} id="support-btn" className="icon-button clickable">
      <MdOutlineQuestionMark size={18} color="var(--color-gray14)" />
    </button>
  );
}
