import Link from "next/link";
import React from "react";
import { FaFacebook, FaLinkedin } from "ui/react-icons/fa";
import { IoLogoFacebook } from "ui/react-icons/io";

export default function SupportFooter() {
  return (
    <footer className="column j-center a-center">
      <h3>Suivez-nous sur les réseaux sociaux</h3>
      <div className="row">
        <Link aria-label="facebook" href="https://www.facebook.com/p/Cabinet-Finance-Conception-100051396266174">
          <FaFacebook color="var(--color-main)" size={33} />
        </Link>
        <Link aria-label="linkedin" href="https://www.linkedin.com/company/syndemos/?originalSubdomain=fr">
          <FaLinkedin color="var(--color-main)" size={35} />
        </Link>
      </div>
    </footer>
  );
}
