"use client";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

// Utilities
import { useMediaQuery } from "../../../../utilities/hooks/useMediaQuery";

// Components
import { Skeleton } from "../../loaders";
import { BreadcrumbsDektop } from "./BreadcrumbsDesktop";
import { BreadcrumbsMobile } from "./BreadcrumbsMobile";
import { slugToString } from "../../../../utilities/tools/converter";

export function Breadcrumbs({ }) {
  const mediaQuery = useMediaQuery("920");
  const pathname = usePathname();
  const [loading, setLoading] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState<{ breadcrumb: string; href: string; }[] | null>(null);

  useEffect(() => {
    pathname && setLoading(false);
  }, [pathname]);

  useEffect(() => {
    const linkPath = pathname?.split("?")[0]?.split("/");

    linkPath?.shift();

    const pathArray = linkPath?.map((path, i) => {
      return {
        breadcrumb: slugToString(path),
        href: "/" + linkPath?.slice(0, i + 1)?.join("/"),
      };
    });

    pathArray && setBreadcrumbs(pathArray);
  }, [pathname]);

  return (
    <>
      {breadcrumbs && breadcrumbs?.length > 1 ? (
        <div id="breadcrumb" className={mediaQuery ? "mobile" : "desktop"}>
          {breadcrumbs && loading ? (
            <Skeleton type="text" height={24} width={20} />
          ) : (
            <>
              {breadcrumbs && breadcrumbs?.length > 1 && (
                <>
                  {mediaQuery
                    ? breadcrumbs && <BreadcrumbsMobile breadcrumbs={breadcrumbs} />
                    : breadcrumbs && <BreadcrumbsDektop breadcrumbs={breadcrumbs} />}
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="mt-2" />
      )}
    </>
  );
}
