import { useRouter } from "next/navigation";
import React from "react";
import { FaPowerOff } from "ui/react-icons/fa";

export default function ConnexionBtn() {
  const router = useRouter();

  return (
    <button aria-label="connexion" id="connexion-btn" onClick={() => router.push("/auth/connexion")}>
      <FaPowerOff color="var(--color-text)" />
    </button>
  );
}
