import React, { useEffect, useState } from "react";
import { Grid } from "../../components/blocs";
import { NotificationTopicIcon } from "../../components/icons";
import { CompanyPicture } from "../../components/pictures";
import { AxiosInstance } from "axios";
import { NotificationType } from "../../../utilities/redux/types";
import moment from "moment";
import "moment/locale/fr";

type Props = {
  fetcher: AxiosInstance;
  notification: NotificationType;
  router: any;
  storeNotifications: (arg: NotificationType[]) => void;
};

export default function NotificationItem({ notification, fetcher, router, storeNotifications }: Props) {
  const [relativeTime, setRelativeTime] = useState("");

  // const allMarkAsRead = () => {
  //   fetcher.put("/notification_user/read-notification/" + notification.id).then((resp) => {

  //     if (resp?.status === 200) {
  //       storeNotifications(resp.data);
  //     }
  //   });
  // };

  // const allMarkAsUnRead = () => {
  //   fetcher.put("/notification_user/unread-notification/" + notification.id).then((resp) => {

  //     if (resp?.status === 200) {
  //       storeNotifications(resp.data);
  //     }
  //   });
  // };

  useEffect(() => {
    moment.locale("fr");
    const momentDate = moment(notification.createdAt, "YYYY-MM-DD HH:mm:ss");
    setRelativeTime(momentDate.fromNow());
  }, [moment, notification]);

  return (
    <Grid
      className={notification.url?.length > 0 ? "notification clickable" : "notification"}
      template="auto 1fr"
      alignItems="center"
      mediaQuery={0}
      space={15}
      onClick={() => {
        notification.url?.length > 0 && router.push(notification.url);
      }}
    >
      <NotificationTopicIcon topic={notification?.topic} size={60} />

      <div className="content column">
        <p className="title">{notification?.topic?.legend}</p>
        <p className="details">{notification?.details?.length > 65 ? notification?.details?.substring(0, 65) + "..." : notification?.details}</p>
        <em>{relativeTime}</em>
      </div>

      {!notification?.isReaded && <div className="readed-indication" />}
      {/* <NotificationCommunicationType notification={notification} /> */}

      {/* <MenuListButton
        className="configuration-btn"
        icon="horizontal"
        items={[
          !notification?.isReaded
            ? { icon: <FaCheck size={20} color="var(--color-success)" />, legend: "Marquer comme lue", cb: () => allMarkAsRead() }
            : { icon: <IoClose size={25} color="var(--color-error)" />, legend: "Marquer comme non lue", cb: () => allMarkAsUnRead() },
        ]}
      /> */}
    </Grid>
  );
}
