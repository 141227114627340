"use client";
import React, { HTMLAttributes, useEffect, useRef, useState } from "react";

import { UserPicture } from "../../pictures";
import { FaChevronDown, FaChevronUp } from "../../../react-icons/fa";
import { Grid } from "../../blocs";
import { UserType } from "../../../../utilities/redux/types";
import classNames from "classnames";

type Div = HTMLAttributes<HTMLDivElement> & Required<{ ["children"]: HTMLAttributes<HTMLUListElement>["children"]; }>;

interface Props extends Div {
  user: UserType;
  picture?: any;
  darkmode: boolean;
}

export function HeaderUserLayout({ user, picture, darkmode, children, ...props }: Props) {
  const childrenArray = Array.isArray(children) ? children : [children];

  const [isOpen, setIsOpen] = useState(false);

  const ref: any = useRef(null);
  const triggerRef = useRef(Array(childrenArray.length).fill([]));

  const toggleMenu = () => {
    setIsOpen((prev) => {
      if (!prev) {
        triggerRef?.current?.[0]?.focus();
      } else {
        ref?.current?.focus();
      }

      return !prev;
    });
  };

  const handleKeyDown = (e) => {
    if (isOpen) {
      e?.preventDefault();
      if (e.key === "Enter" || e.key === "Escape") {
        setIsOpen(false);
      } else if (e.key === "Tab" || e.key === "ArrowDown") {
        triggerRef?.current?.[0]?.focus();
      }
    } else {
      if (e.key === "Enter") {
        toggleMenu();
      }
    }
  };

  const handleKeyItem = (e) => {
    e.preventDefault();
    if (e.key === "ArrowDown") {
      e.target.nextSibling?.focus();
    } else if (e.key === "ArrowUp") {
      e.target.previousSibling?.focus();
    } else if (e.key === "Escape") {
      ref?.current?.focus();
      setIsOpen(false);
    } else if (e.key === "Enter") {
      e.target.click();
    }
  };

  const closeMenu = (e) => {
    if (ref?.current && !ref?.current.contains(e.target)) {
      setIsOpen(false);
      ref?.current?.focus();
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", closeMenu);

    return () => {
      window.removeEventListener("mousedown", closeMenu);
    };
  }, []);

  return (
    <div {...props} id="header-user" className={classNames(props.className, isOpen ? "open" : "closed")} ref={ref}>
      <Grid
        onClick={toggleMenu}
        onKeyDown={handleKeyDown}
        role="button"
        aria-controls={`header-user_dropdown`}
        aria-label={`Menu utilisateur`}
        aria-haspopup="menu"
        tabIndex={0}
        id="user"
        className="clickable"
        template="auto 1fr auto"
        alignItems="center"
        mediaQuery={200}
      >
        <UserPicture size={45} email={user?.email} preview={picture} isPictureDefined={user?.isPictureDefined} />
        <div className="row">
          {user?.firstName} {user?.lastName?.slice(0, 1)}
        </div>
        {isOpen ? <FaChevronUp size={12} color="var(--color-text)" /> : <FaChevronDown size={12} color="var(--color-text)" />}
      </Grid>

      <ul role="menu" id={`header-user_dropdown`} className={isOpen ? "visible" : "hidden"}>
        {childrenArray?.map((item, key) => (
          <li
            {...item?.props}
            key={`menuitem_${key}`}
            ref={(item) => {
              triggerRef.current[key] = item;
            }}
            role="menuitem"
            tabIndex={0}
            onKeyDown={handleKeyItem}
            className={classNames(item?.props?.className, "item")}
          >
            {item.props.children}
          </li>
        ))}
      </ul>
    </div>
  );
}
