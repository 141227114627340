import React, { useEffect, useRef, useState } from "react";
import { CircleLoader } from "../../components/loaders";
import NotificationItem from "./NotificationItem";
import { Button, MenuListButton } from "../../components/buttons";
import { FaCheck } from "../../react-icons/fa";
import { IoMdSettings } from "../../react-icons/io";
import { MdRemoveRedEye } from "../../react-icons/md";
import { MessageBox } from "../../components/infos";
import moment from "moment";
import "moment/locale/fr";

export default function NotificationWindow({
  notificationsPreview,
  storeNotifications,
  notificationsCount,
  storePreviewNotifications,
  storeNotificationsCount,
  fetcher,
  setNotificationWindowIsOpen,
  router,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const ref: any = useRef(null);

  const allMarkAsRead = () => {
    fetcher.put("/notification_user/read-all-notifications").then((resp) => {
      setIsLoading(false);
      if (resp?.status === 200) {
        storeNotifications(resp.data);
      }
    });
  };

  const fetchMyCollection = (signal: AbortSignal) => {
    setIsLoading(true);
    fetcher.post("/notification_user", { limit: 10 }, { signal }).then((resp) => {
      setIsLoading(false);
      if (resp?.status === 200) {
        storePreviewNotifications(resp.data);
      }
    });
  };

  const fetchMyCollectionCount = (signal: AbortSignal) => {
    setIsLoading(true);
    fetcher.post("/notification_user/count", { read: "unread" }, { signal }).then((resp) => {
      setIsLoading(false);
      if (resp?.status === 200) {
        storeNotificationsCount(resp.data);
      }
    });
  };

  useEffect(() => {
    moment.locale("fr");

    let controller = new AbortController();
    let controller2 = new AbortController();
    fetchMyCollection(controller.signal);
    fetchMyCollectionCount(controller2.signal);

    return () => {
      controller.abort();
      controller2.abort();
    };
  }, []);

  return (
    <div id="notification-window" ref={ref}>
      <h2 className="mt-0">Notifications</h2>

      {notificationsPreview?.length == 0 && <MessageBox>Vous n'avez aucune notification</MessageBox>}

      <MenuListButton
        id="configuration-btn"
        icon="horizontal"
        items={[
          {
            icon: <IoMdSettings />,
            legend: "Paramétrage des notifications",
            cb: () => {
              setNotificationWindowIsOpen(false);

              router.push("/dashboard/mes-informations-personnelles/notifications");
            },
          },
        ]}
      />
      {isLoading && <CircleLoader width="90" className="mt-4" />}
      <div id="list">
        {notificationsPreview?.map((notification, key) => {
          return <NotificationItem fetcher={fetcher} router={router} storeNotifications={storeNotifications} notification={notification} key={key} />;
        })}

        {notificationsPreview?.length < notificationsCount && (
          <p className="text-center has-more">Appuyer sur le bouton pour consulter les {notificationsCount - notificationsPreview?.length} restantes</p>
        )}
      </div>

      <Button
        className="m-0 mt-1 w-100"
        type="transparent"
        icon="eye"
        size="small"
        cb={() => {
          setNotificationWindowIsOpen(false);
          router.push("/dashboard/mes-notifications");
        }}
      >
        Accéder au détails
      </Button>
    </div>
  );
}
