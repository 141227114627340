"use client";

import { expertFetcher } from "@/lib/http";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";

import {
  setNotificationWindowIsOpen,
  storeNotifications,
  storeNotificationsCount,
  storePreviewNotifications,
} from "@/redux/slices/notification/NotificationUserSlice";
import { setDarkmode } from "@/redux/slices/StyleSlice";
import { storeUser } from "@/redux/slices/UserSlice";

import { DarkModeBtn } from "ui/components/buttons";
import { Breadcrumbs } from "ui/components/navigation";
import NotificationBtn from "ui/modules/notification-header/NotificationBtn";
import HeaderUser from "../dashboard/_components_layout/aside/HeaderUser";
import ConnexionBtn from "./ConnexionBtn";
import SupportContainer from "./support/SupportContainer";

export default function Header() {
  const MEDIAQUERY = 920;
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.user);
  const darkmode = useAppSelector((state) => state.style.darkmode);
  const windowSize = useAppSelector((state) => state.style.windowSize);
  const pathName = usePathname();
  const router = useRouter();
  // Notifications
  const notifications = useAppSelector(
    (state) => state.notifications?.user?.notifications,
  );
  const notificationsCount = useAppSelector(
    (state) => state.notifications?.user?.notificationsCount,
  );
  const notificationsPreview = useAppSelector(
    (state) => state.notifications?.user?.previewNotifications,
  );
  const isOpen = useAppSelector(
    (state) => state.notifications?.user?.notificationWindowIsOpen,
  );

  function updateThemePreference(theme: string) {
    if (user?.email) {
      expertFetcher
        .put("/user/update-theme-preference", { theme })
        .then((resp) => {
          if (resp?.status === 200) {
            dispatch(setDarkmode(theme));
          }
        });
    } else {
      dispatch(setDarkmode(theme));
    }
  }

  useEffect(() => {
    const ttl = localStorage.getItem("token_ttl");

    if (ttl && user?.email) {
      if (Date.now() > parseInt(ttl) * 1000) {
        dispatch(storeUser(null));
      }
    }
  }, []);

  return (
    <header>
      {!pathName.includes("dashboard") && (
        <Link
          aria-label="dashboard"
          href="/dashboard"
          className="column j-center header"
        >
          {
            <img
              alt="logo"
              style={{ width: "70px" }}
              src={darkmode == false ? "/logo.svg" : "/logo_blank.svg"}
              id="logo"
            />
          }
        </Link>
      )}

      <Breadcrumbs />

      <div className="row a-center">
        {user?.email &&
          notifications &&
          pathName.includes("/dashboard") &&
          (!windowSize || windowSize?.width > MEDIAQUERY) && (
            <NotificationBtn
              user={user}
              router={router}
              fetcher={expertFetcher}
              isOpen={isOpen}
              notificationsCount={notificationsCount}
              notificationsPreview={notificationsPreview}
              storeNotificationsCount={(arg) =>
                dispatch(storeNotificationsCount(arg))
              }
              storePreviewNotifications={(arg) =>
                dispatch(storePreviewNotifications(arg))
              }
              storeNotifications={(arg) => dispatch(storeNotifications(arg))}
              setIsOpen={(arg) => {
                dispatch(setNotificationWindowIsOpen(arg));
              }}
              mediaQuery={false}
            />
          )}

        {!user?.email && (
          <DarkModeBtn
            user={user}
            darkmode={darkmode}
            activeDarkMode={() => updateThemePreference("dark")}
            disactiveDarkMode={() => updateThemePreference("light")}
          />
        )}
        <>{!user?.email && <ConnexionBtn />}</>

        <SupportContainer />

        {user?.email &&
          (!windowSize || windowSize?.width > MEDIAQUERY) &&
          (pathName.includes("/cabinet-supprime") ||
            pathName.includes("/dashboard") ||
            pathName.includes("/informations")) && <HeaderUser />}
      </div>
    </header>
  );
}
