"use client";
import Link from "next/link";

import { FiChevronRight } from "../../../react-icons/fi";

type Props = {
  breadcrumbs: { breadcrumb: string; href: string; }[];
};

export function BreadcrumbsDektop({ breadcrumbs }: Props) {
  return (
    <>
      {breadcrumbs?.map((breadcrumb, index) => (
        <div className="a-center flex" key={`breadcrumbs_${index}`}>
          {index !== 0 && <FiChevronRight size={20} />}

          {index + 1 == breadcrumbs.length
            ? <div className={"item selected"}>
              {breadcrumb.breadcrumb}
            </div>
            : <Link href={breadcrumb.href} className={"item"}>
              {breadcrumb.breadcrumb}
            </Link>
          }
        </div>
      ))}
    </>
  );
}
